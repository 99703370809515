import React, { useMemo } from 'react'
import {
  IFaqSection,
  ISectionOverviewFields,
  ISectionContentBlock,
  ITextBlock,
  IVariationContainer,
  IVideoBlock,
  IMediaGallery,
} from 'src/types/generated/contentful'
import Markdown from '@/components/Markdown'
import { TextBlock, VideoBox } from '@/components/common'
import ContentfulExperiment from '@/components/contentful/ContentfulExperiment'
import FAQ from '@/components/pages/CampaignPages/FAQ'
import { Campaign } from '@/services/api/campaign'
import useSiteAssets from '@/services/hooks/useSiteAssets'
import { contentUtil } from '@/utils'
import { CONTENT_BLOCK_LAYOUT } from '../../../../../../constants/contentful'
import Bios from '../Bios'
import MediaGallery from '../MediaGallery'
import PriorRaisesBreakdown from '../PriorRaisesBreakdown'
import Testimonials from '../Testimonials'

interface Props {
  campaign: Campaign
  section: ISectionOverviewFields
  idx: number
}

type OverviewItem = ISectionOverviewFields['content'][number]

function mapItem(itemRaw: OverviewItem, campaign: Campaign) {
  const contentItem = contentUtil.mapContent<OverviewItem>(
    itemRaw
  ) as contentUtil.MappedContent<OverviewItem>

  if (!contentItem) return null

  let jsx: JSX.Element | null = null

  if (contentItem.contentType === 'sectionContentBlock') {
    const item = contentItem as contentUtil.MappedContent<ISectionContentBlock>
    if (item.layout === CONTENT_BLOCK_LAYOUT.BIO) {
      jsx = <Bios items={item.items} />
    }
    if (item.layout === CONTENT_BLOCK_LAYOUT.TESTIMONIAL) {
      jsx = <Testimonials items={item.items} />
    }
  }

  if (contentItem.contentType === 'faqSection') {
    const item = contentItem as contentUtil.MappedContent<IFaqSection>
    const questions = item.faqs
      ?.map(contentUtil.mapContent)
      .filter(contentUtil.filterEmpty)
    jsx = <FAQ faq={{ questions }} />
  }

  if (contentItem.contentType === 'textBlock') {
    const item = contentItem as contentUtil.MappedContent<ITextBlock>
    jsx = <TextBlock campaignSlug={campaign.slug} document={item.text} />
  }

  if (contentItem.contentType === 'videoBlock') {
    const item = contentItem as contentUtil.MappedContent<IVideoBlock>
    jsx = (
      <VideoBox
        className="-mx-6 lg:mx-0"
        campaignSlug={campaign.slug}
        id={`overview-${item.id}`}
        video={item.id}
        service={item.service}
        aspectRatio={item.aspectRatio}
      />
    )
  }

  if (contentItem.contentType === 'mediaGallery') {
    const item = contentItem as contentUtil.MappedContent<IMediaGallery>
    const items = item.items
      .map(contentUtil.mapContent)
      .filter(contentUtil.filterEmpty)
    jsx = (
      <MediaGallery
        title={item.title}
        description={item.description}
        items={items}
        campaign={campaign}
        className="py-6"
      />
    )
  }

  if (contentItem.contentType === 'variationContainer') {
    const item = contentItem as contentUtil.MappedContent<IVariationContainer>

    jsx = (
      <ContentfulExperiment
        experimentTitle={item.experimentTitle}
        variations={item.variations}
      >
        {(variation) =>
          mapItem(
            variation as Exclude<
              OverviewItem,
              IVariationContainer | IFaqSection
            >,
            campaign
          )
        }
      </ContentfulExperiment>
    )
  }

  return (
    <div key={contentItem.id} className="mb-10 last:mb-0 lg:mb-16">
      {jsx}
    </div>
  )
}

export const Overview = ({ campaign, section, idx }: Props) => {
  const siteAssets = useSiteAssets()

  const items = useMemo(() => {
    const res = section.content.map((item) => mapItem(item, campaign))

    if (siteAssets?.usePriorRaisesLayout && idx === 0) {
      res.unshift(
        <div key="investment-breakdown" className="mb-6 last:mb-0">
          <Markdown source={siteAssets.campaignSummary} />
          <PriorRaisesBreakdown
            campaign={campaign}
            disclaimer={siteAssets.fundingCampaignDisclaimer}
            className="mb-6 rounded-2xl border border-core-gray-200 bg-white p-5 md:p-8"
          />
        </div>
      )
    }

    // TODO: leaving this here in case we need it for david-2 again
    // if (campaign.slug === 'angel-2' && idx === 0) {
    //   res.unshift(
    //     <div key="investment-breakdown" className="mb-6 last:mb-0">
    //       <InvestmentChatbot campaign={campaign} />
    //     </div>
    //   )
    // }

    return res
  }, [
    section.content,
    campaign,
    idx,
    siteAssets.campaignSummary,
    siteAssets.fundingCampaignDisclaimer,
    siteAssets.usePriorRaisesLayout,
  ])

  return <div className="h-full">{items}</div>
}

export default Overview
